import React from 'react'
import { Link } from 'react-router-dom'

import Page from '../template/Page'
import Header from '../template/Header'
import Section from '../template/Section'

import LiquidCommons from './parts/LiquidCommons'

class OverWaterkwaliteit extends React.Component {

  render() {
    return (
      <Page>
        <Header>
          <h1>Over Waterkwaliteit</h1>
        </Header>

        <LiquidCommons />

        <Section bg="dark">
          <h3>Verder naar...</h3>
          <ul>
            <li><Link to="/over-waterkwaliteit/water-in-amsterdam/">Water in Amsterdam</Link></li>
            <li><Link to="/over-waterkwaliteit/waterverontreiniging/">Waterverontreiniging</Link></li>
            <li><Link to="/over-waterkwaliteit/riolering/">Riolering</Link></li>
            <li><Link to="/over-waterkwaliteit/ecologische-waterkwaliteit/">Ecologische waterkwaliteit</Link></li>
            <li><Link to="/over-waterkwaliteit/zwemwaterkwaliteit/">Zwemwaterkwaliteit</Link></li>
          </ul>
        </Section>

      </Page>
    )
  }
}

export default OverWaterkwaliteit
