import React from 'react'


class Stadsdoorsnede extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      active : "start"
    }
  }

  setActive(part) {
    const active = (this.state.active === part ? "start" : part)

    this.setState({ active })
  }


  render() {
    const { active } = this.state

    return (
      <section id="stadsdoorsnede" className="image-toggle">
        <img src={`/images/stadsdoorsnede-${active}.jpg`} alt="" data-object-fit="cover" />

        <div className="text-panel right">
          <h2 onClick={()=> this.setActive("start")}>Hydrologisch systeem van Amsterdam</h2>

          <ul className="toggles">

            <li className={(active === "start" ? "active" : null)}>
              {/* <h3 onClick={()=> this.setActive("systeem")}>Systeem</h3> */}
              <div className="toggle-content">
                <p>Doorgaans gaan we er van uit dat de stad functioneert. Pas als het mis gaat, worden kritische vragen gesteld. Gelukkig hebben we in Nederland, en met name in Amsterdam, een zeer hoge standaard als het gaat om watermanagement. Deze stadsdoorsnede toont wat normaal onzichtbaar is.</p>
              </div>
            </li>

            <li className={(active === "opwoeling" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("opwoeling")}>Opwoeling</h3>
              <div className="toggle-content">
                <p>De diepte van het water in Amsterdam varieert van twee tot vier meter diepte. Wanneer boten met grote schroeven door het water varen woelen ze de bodem op. Dit zorgt voor troebelheid, waardoor licht wordt geblokkeerd. Daarnaast ligt op de bodem vaak verontreiniging. Op de bodem zijn ze zo goed als onschadelijk, maar na opwoeling vormen ze weer verontreiniging.</p>
              </div>
            </li>

            <li className={(active === "woonboot" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("woonboot")}>Woonboot</h3>
              <div className="toggle-content">
                <p>Lange tijd loosden woonboten direct op de grachten. De afgelopen jaren is actief beleid gevoerd om deze te koppelen aan het riool. Op dit moment (medio 2019) zijn vrijwel alle 
woonboten aangesloten.</p>
              </div>
            </li>

            {/* <li className={(active === "regen" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("regen")}>Regen</h3>
              <div className="toggle-content">
                <p></p>
              </div>
            </li> */}

            <li className={(active === "overstort" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("overstort")}>Overstort</h3>
              <div className="toggle-content">
                <p>In het plaatje is een overstortriool met een geel deksel afgebeeld. De overstorten vinden plaats bij een put waar verschillende riolen op aansluiten. De overstort werkt met een drempel; bij geen of normale regenval word de drempel niet overschreden en gaat al het water naar de zuivering. Wanneer het hard regent stijgt het peil binnen het stelsel en loop het water over de drempel. Hier bezinken eerst de zware delen waarna de rest de grachten in stroomt. Dit gebeurt onder de waterspiegel.</p>
              </div>
            </li>

            <li className={(active === "uitspoeling" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("uitspoeling")}>Uitspoeling</h3>
              <div className="toggle-content">
                <p>Regenwater stroomt voor een deel het riool in. Een ander deel stroomt direct het oppervlaktewater in. Deze verontreiniging bevat vaak zware metalen, drijfvuil en organisch materiaal. In stedelijke gebieden worden meer zware metalen en drijfafval aangetroffen, in agrarisch land meer nutriënten en pesticiden.</p>
              </div>
            </li>

          </ul>
        </div>

      </section>
    )
  }
}

export default Stadsdoorsnede
