import React from 'react'

import Page from '../template/Page'
import Header from '../template/Header'

import InVogelvlucht from './parts/InVogelvlucht'
import Stroomgebied from './parts/Stroomgebied'
import Stroming from './parts/Stroming'
import Stadsdoorsnede from './parts/Stadsdoorsnede'

class WaterInAmsterdam extends React.Component {
  render() {
    return (
      <Page>
        <Header image="/images/header-water-in-amsterdam.jpg">
          <h1>Water in Amsterdam</h1>
        </Header>

        <Stroomgebied />
        <InVogelvlucht />
        <Stroming />
        <Stadsdoorsnede />
      </Page>
    )
  }
}

export default WaterInAmsterdam