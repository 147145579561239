import React from 'react'
import Section from '../../template/Section'

class ZuurstofBiodiversiteit extends React.Component {
  render() {
    return (
      <Section id="zuurstof-biodiversiteit" bg="light">
        <div className="columns">
          <div className="column column-width-s-12 column-width-m-12 column-width-l-4">
            <h2>Zuurstof en biodiversiteit</h2>
            <p>De afgelopen decennia is door een hele reeks maatregelen de waterkwaliteit verbeterd in het stroomgebied van de Rijn en dus van het oppervlaktewater van Amsterdam. Dit is de verdienste van vele acties op verschillende schalen, zoals internationale lozingsverdragen, het verplaatsen van rioolwaterzuiveringsinstallaties buiten de stad en het aansluiten van woonboten op het riool. Zijn de grachten nu schoon?</p>
            <p>Metingen in de Rijn aan de grens van Nederland tonen toenemende zuurstofwaarden en biodiversiteit sinds het eind van de jaren ’70. Deze nieuwe condities bieden nieuwe kansen, maar ook de uitdaging en verantwoordelijkheid om deze positieve tendens voort te zetten.</p>
          </div>
          <div className="column column-width-s-12 column-width-m-12 column-width-l-8">
            <div className="caption">
              <h4>Zuurstof en Biodiversiteit in de Rijn</h4>
            </div>
            <img src="/images/zuurstof-biodiversiteit.svg" alt="" />
          </div>
        </div>
      </Section>
    )
  }
}

export default ZuurstofBiodiversiteit
