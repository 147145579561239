import * as d3 from 'd3'
import { json as d3JSON } from 'd3-request'
import { hours } from '../util/datetime.js'

const parseTimestamp = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L%Z")

const formatData = (data) => {
  const formattedData = data.map(d => {
    return {
      timestamp: parseTimestamp(d.timestamp).getTime(),
      avg: Number(d["50"]),
      minmax: [Number(d["5"]), Number(d["95"])]
    }
  })

  return formattedData
}

// complement data array with empty instances if data is missing
const complementData = (data, windowSize) => {
  const dataStart = data[0].timestamp
  const dataEnd = new Date().getTime()
  const existing = data.map(x => x.timestamp)

  for (let i = dataStart; i < dataEnd; i = i + windowSize) {
    if(existing.indexOf(i) < 0) {
      data.push({
        timestamp: i,
        avg: null,
        minmax: [null, null]
      })
    }
  }
  data.sort((x,y) => x.timestamp - y.timestamp);

  return data
}


const fetchMunisenseJSON = (name, timeDomain, interval) => {
  return new Promise((resolve, reject) => {

    const start = new Date(timeDomain[0])
    const end = new Date(timeDomain[1])
    const windowSize = hours(interval)

    //const offset = end.getTimezoneOffset() / -60
    //end.setHours(end.getHours() + offset)

    return d3JSON("https://portal.water-munisense.net/webservices/v2/waterqualitymeasurementpoints/2986/"
    //return d3JSON("https://amsterdecks.com/munisense/"
      + encodeURIComponent(name)
      + "/querypercentiles/" + encodeURIComponent(start.toISOString())
      + "?end_timestamp=" + encodeURIComponent(end.toISOString())
      + "&window_size=" + encodeURIComponent(windowSize)
      + "&percentiles=5,50,95"
      + "&sample_rate=100"
      + "&rowcount=1000"
    )
    .header("Authorization", "Basic d2FhZ3NvY2lldHlhcGk6N2ZiTmN5YW5QRUEzTnVSTA==")
    .get((error, apiData) => {
      if (error) {
        reject(error)
      } else {
        const formattedData = formatData(apiData.results)
        const complementedData = complementData(formattedData, windowSize)

        resolve(complementedData)
      }
    })
  })
}

export default fetchMunisenseJSON