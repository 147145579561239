import React from 'react'
import Section from '../../template/Section'

class Stroomgebied extends React.Component {
  render() {
    return (
      <Section id="stroomgebied" bg="dark">
        <div className="columns">
          <div className="column column-width-s-12 column-width-m-8">
            <h2>Stroomgebied</h2>
            <p>Amsterdam behoort tot het stroomgebied van de Rijn. Een stroomgebied is een gebied waarvan alle neerslag uitkomt in dezelfde riviermond. De grenzen worden bepaald door bergruggen die in één richting afwateren naar de Nederlandse delta.</p>
            <p>Binnen het stroomgebied bevinden zich weer allerlei andere grenzen die op andere schalen politieke en geografische verschillen aanduiden. Het stroomgebied van de Rijn kan worden gezien als een transnationale gemeenschap, waar mensen van zeven verschillende landen gezamenlijk zorg dragen voor de waterkwaliteit. Voor Nederland, dat zich benedenstrooms bevind, is het stimuleren van schoon water door het gehele stroomgebied van extra groot belang.</p>
          </div>
          <div className="column column-width-s-12 column-width-m-4">
            <div className="caption">
              <h3>Hydrologische Cyclus</h3>
            </div>
            <img src="/images/hydrologische-cyclus.png" alt="" />
          </div>
        </div>
        <div className="columns align-start">
          <div className="column column-width-s-12 column-width-m-4">
            <div className="caption">
              <h3>1. Stroomgebied</h3>
              <h4>Rijn</h4>
            </div>
            <img src="/images/stroomgebied-rijn.png" alt="" />
          </div>
          <div className="column column-width-s-12 column-width-m-4">
            <div className="caption">
              <h3>2. Waterschap</h3>
              <h4>Amstel - Gooi - Vecht</h4>
            </div>
            <img src="/images/waterschap-amstel-gooi-vecht.png" alt="" />
          </div>
          <div className="column column-width-s-12 column-width-m-4">
            <div className="caption">
              <h3>3. Gemaal buurten</h3>
              <h4>Amsterdam</h4>
            </div>
            <img src="/images/gemaal-buurten.png" alt="" />
          </div>
        </div>
      </Section>
    )
  }
}

export default Stroomgebied
