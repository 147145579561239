import React from 'react'

import Page from '../template/Page'
import Header from '../template/Header'
import Section from '../template/Section'

import ZuurstofBiodiversiteit from './parts/ZuurstofBiodiversiteit'

class EcologischeWaterkwaliteit extends React.Component {
  render() {
    return (
      <Page>
        <Header image="/images/header-water-in-amsterdam.jpg">
          <h1>Ecologische Waterkwaliteit</h1>
        </Header>

        <ZuurstofBiodiversiteit />

        <Section bg="dark">
          <h2>Zuurgraad van het water</h2>
          <div className="columns">
            <div className="column column-width-s-12 column-width-m-6">
              <p>De zuurgraad geeft een indicatie van het ecologisch evenwicht in het water. Bacteriën, vissen en waterdieren produceren koolzuur (CO<sub>2</sub>), waardoor het water beetje bij beetje zuurder wordt. Met behulp van zonlicht gebruiken waterplanten CO<sub>2</sub> om te groeien, waardoor de zuurgraad van het water toeneemt (minder zuur wordt).</p>
            </div>
            <div className="column column-width-s-12 column-width-m-6">
              <p>Planten groeien niet als de zon onder is, maar er wordt ’s nachts wel CO<sub>2</sub> geproduceerd. Daarom is de pH-waarde in de ochtend lager dan overdag. Op een zonnige dag zal er ’s avonds veel CO<sub>2</sub> zijn opgenomen door de waterplanten, waardoor de pH-waarde op dat moment hoger is.</p>
            </div>
          </div>
        </Section>

        <Section bg="light">
          <div className="columns">
            <div className="column column-width-s-12 column-width-m-12">
              <h2>Voedselrijk &amp; voedselarm</h2>
              <p>Hoe <em>meer</em> voedsel er aanwezig is in een rivier of meer, hoe <em>eenzijdiger</em> de planten- en dierenwereld. Voedselrijke bronnen zijn bijvoorbeeld rioolwater en meststoffen. Als deze stoffen in het water terechtkomen, zal onstuimige algengroei ontstaan. Alle dieren en planten die niet met de alg kunnen concurreren, zullen dan verdwijnen.</p>
              <p>Als er <em>weinig</em> voedsel is, kunnen er veel meer verschillende waterplanten groeien. Die houden het water schoon en transparant, waardoor zonlicht dieper reikt en er weer meer planten kunnen groeien. Dit biedt een rijkere omgeving voor allerlei soorten vissen, reptielen en andere organismen.</p>
              <p>Om deze biodiversiteit te behouden, wordt a.o. rioolwater gezuiverd voor het in het water geloosd wordt. Scheepvaart, woonboten en volkstuintjes in het buitengebied van Amsterdam zijn vaak nog niet aangesloten op het rioolstelsel en dragen daardoor nog bij aan een verminderde biodiversiteit. Andere bronnen zijn bijvoorbeeld landbouw, uitspoeling van voedselrijke bodems en industrie. Gezuiverd rioolwater is wel een stuk schoner, maar bevat nog steeds veel ziektekiemen. Zwemmen bij een waterzuivering en de paar kilometers stroomafwaarts is daarom heel onverstandig.</p>
            </div>
          </div>
        </Section>

        <Section bg="dark">
          <div className="columns">
            <div className="column column-width-s-12 column-width-m-6">
              <h2>Zelf aan de slag!</h2>
              <p>Wil je zelf meten wat de waterkwaliteit is in je vijver, sloot of gracht? Met deze drie eenvoudige methodes kun je:</p>
              <ul>
                <li>• <a href="http://smartkidslab.nl/content/1-maak-een-meter/7-helderheidsmeter/SmartKidsLab_Helderheidsmeter.pdf" target="_blank" rel="noopener noreferrer">de helderheid van het water meten</a></li>
                <li>• <a href="http://smartkidslab.nl/content/1-maak-een-meter/1-ph-meter-maken/SmartKidsLab_Zuurmeter.pdf" target="_blank" rel="noopener noreferrer">de zuurgraad bepalen</a></li>
                <li>• <a href="http://smartkidslab.nl/content/1-maak-een-meter/9-mineralenmeter/SmartKidsLab_Mineralenmeter.pdf" target="_blank" rel="noopener noreferrer">vaststellen hoeveel mineralen er in het water zitten</a></li>
              </ul>
            </div>
            <div className="column column-width-s-12 column-width-m-6">
              <img src="/images/smart-kids-lab.jpg" alt="zelf waterkwaliteit meten" />
            </div>
          </div>
        </Section>
      </Page>
    )
  }
}

export default EcologischeWaterkwaliteit