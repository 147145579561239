import React from 'react'
import { connect } from 'react-redux'
import { setActiveChart, setInfoBox } from '../actions'

import { roundBy } from './../util/utility.js'

import { chartHeight, chartPadding } from './settings/defaults'
import colors from './settings/colors.js'
import parameters from './settings/parameters.js'

import ActiveDot from './ActiveDot'

import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Brush,
  Tooltip,
  ReferenceArea
} from "recharts"

const warnings = parameters.reduce((warnings, parameter) => {
  return warnings.concat(parameter.warnings)
}, [])


const WarningLabel = (props) => {
  const { x, y, width } = props.viewBox

  if(!x) return null 

  return (
    <g className="warning" transform={`translate(${x + width / 2} ${y+2})`}>
      <circle cx="0" cy="0" r="8" />
      <text y="4">!</text>
    </g>
  )
}


class ParameterChart extends React.Component {

  constructor(props){
    super()

    this.state = {}

    this.wrapper = React.createRef()
  }

  setActiveParameter(parameter) {
    this.props.dispatch(setActiveChart(parameter))
  }

  handleHeaderClick(e, title, description) {
    e.stopPropagation()
    
    const left = e.screenX + 20
    const top = e.screenY - chartHeight + (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0)
    const content = description

    this.props.dispatch(setInfoBox({ content, style: { left, top }}))
  }

  handleWarningClick(e, start, end, type) {
    e.stopPropagation()

    const orientation = (e.screenX < window.innerWidth / 2 ? 'right' : 'left')

    

    const left = (orientation === 'right' ? e.screenX + 20 : e.screenX - 20) 
    const top = e.screenY - chartHeight + (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0)
    const content = warnings.find(w => w.name === type).content

    this.props.dispatch(setInfoBox({ content, className: orientation, style: { left, top }}))
  }

  render() {
    const { data, events, settings, latest } = this.props
    const { domain } = this.props.domainSettings
    const { startIndex, endIndex } = this.props.brushSettings

    const Warnings = events.map((event, i) => {
      const start = (event.start < domain[0] ? domain[0] : event.start)
      const end = (event.end > domain[1] ? domain[1] : event.end)

      return (
        <ReferenceArea key={i} x1={start} x2={end} ifOverflow="extendDomain" onClick={(e) => {this.handleWarningClick(e, 0, 0, event.type)}} label={<WarningLabel />} />
      )
    })
    
    return (
      <div id={settings.name} className="chart" onMouseEnter={ () => this.setActiveParameter(settings.name) } ref={this.wrapper}>
        <div className="chart-header chart-part" style={{ height: chartHeight, left: chartPadding - 20 }} onClick={(e) => { this.handleHeaderClick(e, settings.title, settings.description)}}>
          <header>
            {settings.title}&nbsp;
            {/* <span className="suffix">{parameter.suffix}</span> */}
          </header>
        </div>
        <ResponsiveContainer width="100%" height={chartHeight + 20}>
          <ComposedChart data={data} syncId="wqv" margin={{top: 0, right: 0, bottom: 0, left: chartPadding }}>
            <defs>
              <linearGradient id={`yaxis-${settings.name}`} x1="0" y1="0" x2="0" y2={chartHeight} gradientUnits="userSpaceOnUse">
                {settings.levels.map(s => {
                  return <stop key={s.offset} offset={`${(1-s.offset/settings.domain[1]) * 100}%`} stopColor={colors[s.color]} />
                })}
              </linearGradient>
            </defs>
            <XAxis dataKey="timestamp" scale="point" hide={true} padding={{ left: 4, right: 4 }} />
            <YAxis dataKey="avg" orientation="right" domain={settings.domain} ticks={settings.tickValues} fontSize="0.7rem" stroke="#FFF" width={chartPadding} interval="preserveStartEnd" allowDataOverflow={true} padding={{ top: 10, bottom: 10 }} axisLine={false} />

            { Warnings }

            <Tooltip animationDuration={0} content={()=> null} cursor={{ stroke: '#FFF', strokeWidth: 8, opacity: 0.25, backgroundBlendMode: "multiply" }} />

            <Area type="monotoneX" dataKey="minmax" activeDot={false} stroke="none" fill={`url(#yaxis-${settings.name})`} fillOpacity="1" animationDuration={500} />
            <Line type="monotoneX" dataKey="avg" dot={false} activeDot={{ stroke: '#233f4d', strokeWidth: 2, r: 14 }} stroke={`url(#yaxis-${settings.name})`} strokeWidth={1} animationDuration={500} />
            <Line type="monotoneX" dataKey="avg" dot={false} activeDot={<ActiveDot suffix={settings.suffix} parameter={settings.name} />} stroke="#000" strokeWidth={1} strokeDasharray="3 3" animationDuration={500} />

            <Brush dataKey="timestamp" width={0} height={0.00001} startIndex={startIndex} endIndex={endIndex} />
          </ComposedChart>
        </ResponsiveContainer>
        <div className="chart-footer chart-part" style={{ height: chartHeight, right: chartPadding - 20 }}>
          { (latest ? <div className="current-value tooltip" style={{ top: chartHeight - chartHeight * data[data.length-1].avg / settings.domain[1] }}>
            { (data[data.length-1].avg ? <span><strong>{roundBy(data[data.length-1].avg, 2)}</strong> {settings.suffix}</span> : 'geen data') }
          </div> : null) }
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => ({
  activeChart: state.activeChart,
  domainSettings: state.domainSettings,
  brushSettings: state.brushSettings,
  latest: state.latest
})
ParameterChart = connect(mapStateToProps)(ParameterChart)


export default ParameterChart