import React from 'react'

import Page from '../template/Page'
import Header from '../template/Header'

import Rioolsystemen from './parts/Rioolsystemen'
import Overstorten from './parts/Overstorten'

class Riolering extends React.Component {
  render() {
    return (
      <Page>
        <Header>
          <h1>Riolering</h1>
        </Header>

        <Rioolsystemen />
        <Overstorten /> 
      </Page>
    )
  }
}

export default Riolering