
const colors = {
  normal: "#FFF",
  medium: "#b7b709",
  high: "#ba0848"
}

// const colors = {
//   normal: "#FFF",
//   medium: "#34f7fe",
//   high: "#150173"
// }

export default colors