export const toFixed = (num, fixed) => {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  return num.toString().match(re)[0]
}


export const round = (number, decimals) => {
  return +(Math.round(number + `e+${decimals}`) + `e-${decimals}`)
}

export const roundBy = (number, decimals) => {
  const x = Math.pow(10, decimals)

  return Math.round(number * x) / x
}


export const timeCeil = (time, interval) => {
  // if(interval > 24) {
  //   time.setHours(0)
  // } else {
    
  // }
  time.setHours(Math.ceil(time.getHours() / interval) * interval)
  time.setMinutes(0)
  time.setSeconds(0)
  time.setMilliseconds(0)
  return time
}


export const getBeginTime = (time, interval) => {
  const beginTime = new Date(time)
  beginTime.setHours(time.getHours() - interval)

  return beginTime
}


