import presets from './settings/presets.js'
import locations from './settings/locations.js'

const getPresetSettings = (preset, location) => {
  let s = {}

  const presetSettings = presets.find(p => p.name === preset)
  const locationSettings = locations.find(p => p.name === location)

  s.preset = preset
  s.days = presetSettings.days
  s.interval = presetSettings.interval

  s.end = new Date()
  s.end.setMinutes(0)
  s.end.setSeconds(0)
  s.end.setMilliseconds(0)

  s.end.setHours(Math.ceil(s.end.getHours() / s.interval) * s.interval)

  s.start = new Date(s.end)
  s.start.setDate(s.start.getDate() - presetSettings.days * presetSettings.load)

  if(s.start < locationSettings.dataStart) {
    s.start = locationSettings.dataStart
  }

  return s
}
export default getPresetSettings