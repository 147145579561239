import React from 'react'
import Section from '../../template/Section'

class Uitspoeling extends React.Component {
  render() {
    return (
      <Section id="uitspoeling" bg="light">
        <div className="columns">
          <div className="column column-width-s-12 column-width-l-6 column-width-xl-4">
            <h2>Uitspoeling en grondgebruik</h2>
            <p>Als het regent stroomt water via het oppervlak naar beken, sloten, rivieren en uiteindelijk naar zee. Materiaal dat op de oppervlakte van parkeerplaatsen, weilanden, industrieterreinen ligt komt dan in het watersysteem. Bepaalde types grondgebruik hebben kenmerkende profielen. Zo komen uit industriegebieden met name chemicaliën, uit de landbouw nutriënten en in de stad drijfvuil en bacteriën.</p>
            <p>Hier ziet u verhoudingsgewijs de grondgebruiken binnen het Waterschap Amstel, Gooi en Vecht, waartoe Amsterdam behoort.</p>
          </div>
          <div className="column column-width-s-12 column-width-l-6 column-width-xl-5">
            <img src="/images/grondgebruik.svg" alt="" />
          </div>
        </div>
        <div className="columns">
          <div className="column column-width-s-12 column-width-l-8 column-width-xl-10">
            <img src="/images/uitspoeling.png" alt="" />
          </div>
        </div>
      </Section>
    )
  }
}

export default Uitspoeling
