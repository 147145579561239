import React from 'react'

const Section = (props) => {
  return (
    <section id={props.id} className={`bg-${props.bg}`}>
      <div className="wrapper">
        {props.children}
      </div>
    </section>
  )
}

export default Section