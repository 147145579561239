import React from 'react'

import Navigation from './template/Navigation'
import Section from './template/Section'

class OverAmsterdecks extends React.Component {

  render() {
    return (
      <div>
        <Navigation initial="visible" />
        <header id="page-header" className="bg-image-header">
          <div className="image" style={{ backgroundImage: 'url("/images/header-over-amsterdecks.jpg")' }}></div>
          <div className="text">
            <div className="wrapper">
              <h1 className="title"><br/><br/>Over Amsterdecks</h1>
              <p className="introduction">Amsterdecks brengt water dichtbij met een slim zwemvlonder dat de waterkwaliteit direct zichtbaar maakt. Vier cilinders op de vlonder geven <em>real-time</em> de meetwaarden weer van vier watersensoren die onder de vlonder hangen. Op deze website vind je meer informatie over en visualisatie van de meetwaarden.<br/><br/></p>
            </div>
          </div>
        </header>

        <Section bg="light">
          <div className="wrapper">
            <div className="columns" style={{ justifyContent: "center" }}>
              <div className="column column-width-s-12 column-width-m-5">
                <p>Met het project Amsterdecks willen we de maatschappelijke betrokkenheid bij waterkwaliteit vergroten. Naar voorbeeld van Nobelprijswinnares Elinor Ostrom willen we met Amsterdecks een stap zetten naar een <em>commons</em> van water: een <em>Liquid Commons</em>. Ostrom toonde aan dat onder specifieke omstandigheden groepen, instituten en landen zonder wetgeving of marktwerking via collectieve afspraken gemeenschappelijke grondstoffen op een duurzame wijze kunnen exploiteren.</p>
              </div>
              <div className="column column-width-s-12 column-width-m-5">
                <p>Liquid Commons bouwt voort op deze precedenten in relatie tot de waterkwaliteit in Amsterdam. Het doel is om in samenwerking met bewoners, ondernemers en beleidsmakers projecten te realiseren die bijdragen aan het duurzame gezamenlijke beheer van de Amsterdamse waterkwaliteit.</p>
              </div>
            </div>
          </div>
        </Section>

        <Section id="contact" bg="dark">
          <div className="columns" style={{ justifyContent: "center" }}>
            <div className="column column-width-s-12 column-width-m-10">
              <h2>Contact</h2>
            </div>
            <div className="column column-width-s-12 column-width-m-5">
              <p>De essentie van een duurzame Liquid Commons is een brede maatschappelijke participatie. In 2019 beginnen we met de aanleg van Amsterdecks en we zoeken daarom allianties en samenwerkingsverbanden door heel Amsterdam.</p>
              <p>Wij nodigen buurtbewoners, beleidsmakers, investeerders, ondernemers en iedereen die wil bijdragen aan een schone en duurzame toekomst voor Amsterdam uit om deel uit te maken van het project.</p>
            </div>
            <div className="column column-width-s-12 column-width-m-5">
              <p>Bent u geïnteresseerd, wilt u bijdragen aan schoner water in Amsterdam of ziet u mogelijkheden voor een Amsterdeck bij u in de buurt?</p>
              <p>Neem contact met ons op!</p>
              <p><a className="button" href="mailto:cdv@rademacherdevries.com">Stuur een email</a></p>
            </div>
          </div>
        </Section>

        <Section id="partners" bg="light">
          <div className="columns" style={{ justifyContent: "center" }}>
            <div className="column column-width-s-12 column-width-m-5">
              <h2>Partners</h2>
              <p>Amsterdecks is een project van <a href="http://www.rademacherdevries.com/" target="_blank" rel="noopener noreferrer">Rademacher de Vries Architecten</a> en <a href="https://waag.org/" target="_blank" rel="noopener noreferrer">Waag</a>, met steun van de <a href="https://www.amsterdam.nl/" target="_blank" rel="noopener noreferrer">Gemeente Amsterdam</a>/<a href="https://www.amsterdamsebos.nl/" target="_blank" rel="noopener noreferrer">Amsterdamse Bos</a>, <a href="https://www.waternet.nl/" target="_blank" rel="noopener noreferrer">Waterschap Amstel, Gooi en Vecht</a>, <a href="https://www.rijnland.net/" target="_blank" rel="noopener noreferrer">Hoogheemraadschap Rijnland</a>, <a href="https://www.doen.nl/" target="_blank" rel="noopener noreferrer">Stichting DOEN</a> en <a href="https://stimuleringsfonds.nl/" rel="noopener noreferrer">Stimuleringsfonds Creatieve Industrie</a>. Alle rechten voorbehouden.</p>
            </div>
            <div className="column column-width-s-12 column-width-m-5">
              <h2>Team</h2>
              <p>
                <strong>RDVA:</strong> Christopher de Vries, David Rademacher<br/>
                <strong>Waag:</strong> Alain Otjens, Dave Gönner, Emma Pareschi, Lotte Geeraedts, Taco van Dijk, Ilyas bin Sarib, Coen Bergman, Tom Demeyer, Job Spierings, Martin Risseeuw, Ivo de Kler<br/>
                <strong>Waterschap Amstel, Gooi en Vecht:</strong> Maarten Ouboter, Ben Staring, Hans van der Pal, Jan Willem Voort, Eljakim Koopman, Thomas Staverman, Kees van der Lugt, Liesbeth Hersbach<br/>
                <strong>Munisense:</strong> Hans Mulder, Joost Pastoor
              </p>
            </div>
          </div>
          {/* munisense */}
          <div className="columns" style={{ alignItems: "center" }}>
            <div className="column column-width-s-10 column-width-m-4">
              <img src="/images/logo-rdva.svg" alt="Logo Rademacher de Vries Architecten" />
            </div>
            <div className="column column-width-s-10 column-width-m-4">
              <img src="/images/logo-waag.svg" alt="Logo Waag" />
            </div>
          </div>
          <div className="columns" style={{ alignItems: "center" }}>
            <div className="column column-width-s-10 column-width-m-3">
              <img src="/images/logo-amsterdamse-bos.png" alt="Logo Amsterdamse Bos" />
            </div>
            <div className="column column-width-s-7 column-width-m-2">
              <img src="/images/logo-gemeente-amsterdam.svg" alt="Logo Gemeente Amsterdam" />
            </div>
            <div className="column column-width-s-10 column-width-m-4">
              <img src="/images/logo-waternet.png" alt="Logo Waternet" />
            </div>
          </div>
          <div className="columns" style={{ alignItems: "center" }}>
            <div className="column column-width-s-10 column-width-m-3">
              <img src="/images/logo-rijnland.png" alt="Logo Hoogheemraadschap Rijnland" />
            </div>
            <div className="column column-width-s-10 column-width-m-3">
              <img src="/images/logo-stimuleringsfonds.svg" alt="Logo Stimuleringsfonds Creatieve Industrie" />
            </div>
            <div className="column column-width-s-7 column-width-m-2">
              <img src="/images/logo-doen.svg" alt="Logo Stichting DOEN" />
            </div>
          </div>
        </Section>
      </div>
    )
  }
}

export default OverAmsterdecks
