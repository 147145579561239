import React from 'react'
import { Link } from 'react-router-dom'
import { hours } from '../../util/datetime.js'
import compress from '../../util/compress.js'

const parameters = [
  {
    name: "dissolvedoxygen_temperature",
    title: "temperatuur",
    description: (
      <p>De watertemperatuur is een belangrijke conditie voor micro-organismen in het water. Een hogere temperatuur verhoogt bijvoorbeeld de kans op <Link to="/over-waterkwaliteit/zwemwaterkwaliteit/">blauwalg</Link>. Bij koud water moeten zwemmers oppassen voor <Link to="/over-waterkwaliteit/zwemwaterkwaliteit/">onderkoeling</Link>.</p>
    ),
    suffix: "°C",
    scale: "scaleLinear",
    domain: [0,30],
    tickValues: [0,5,10,15,20,25,30],
    levels: [
      {
        offset: 26,
        color: "high"
      },
      {
        offset: 21,
        color: "medium"
      },
      {
        offset: 18,
        color: "normal"
      }
    ],
    warnings: [
      {
        name: "water-is-warm",
        condition: compress(value => value.avg != null && value.avg > 22.5, hours(72)),
        content: (
          <div>
            <h4>Hoge watertemperatuur</h4>
            <p>De temperatuur van het water is al enige dagen hoog. Dit vergroot de kans op blauwalg.</p>
            <Link className="more" to="/over-waterkwaliteit/zwemwaterkwaliteit/">Meer info</Link>
          </div>
        )
      },
      {
        name: "water-is-cold",
        condition: compress(value => value.avg != null && value.avg < 10, hours(24)),
        content: (
          <div>
            <h4>Lage watertemperatuur</h4>
            <p>De temperatuur van het water is behoorlijk laag. Pas op voor onderkoeling als je gaat zwemmen!</p>
            <Link className="more" to="/over-waterkwaliteit/zwemwaterkwaliteit/">Meer info</Link>
          </div>
        )
      },
    ]
  },
  {
    name: "dissolvedoxygen",
    title: "zuurstof",
    description: (
      <p>Voldoende zuurstof in het water is een teken van een lage belasting met voedingstoffen en organisch slib, beide voorwaarden voor een goede biodiversiteit. Als riolen overlopen in stedelijk gebied, komt er veel organisch slib in het water en is zuurstof laag. Weer stijgend zuurstof is dan een teken dat het water zich herstelt. Bij veel algengroei zien we grote schommelingen van zuurstof over de dag: bij licht is het zuurstofgehalte hoog, in het donker laag.<br />
        <Link className="more" to="/over-waterkwaliteit/ecologische-waterkwaliteit/">Meer info</Link>
      </p>
    ),
    suffix: "mg/l",
    scale: "scaleLinear",
    domain: [0,15],
    tickValues: [0,3,6,9,12,15],
    levels: [
      {
        offset: 6.5,
        color: "normal"
      },
      {
        offset: 4,
        color: "medium"
      },
      {
        offset: 1.5,
        color: "high"
      }
    ],
    warnings: [
      {
        name: "low-oxygen",
        condition: compress(value => value.avg != null && value.avg < 3 && value.avg > 0, hours(48)),
        content: (
          <div>
            <h4>Laag zuurstofgehalte</h4>
            <p>Het zuurstofgehalte is erg laag. Als dit structureel is kan het vissterfte veroorzaken.</p>
            <Link className="more" to="/over-waterkwaliteit/ecologische-waterkwaliteit/">Meer info</Link>
          </div>
        )
      }
    ]
  },
  {
    name: "ph",
    title: "zuurgraad",
    description: (
      <p>De zuurgraad wordt ook wel pH-waarde genoemd. In schoon water zien we een zuurgraad in balans, met een pH-waarde tussen de 7 en 8,5. Is de pH-waarde hoog, dan kan dit veroorzaakt worden door algengroei. Bij afbraak van algen aan het einde van een bloei, zal de pH-waarde verlagen.</p>
    ),
    suffix: "pH",
    scale: "scaleLinear",
    domain: [0,14],
    tickValues: [0,3,7,11,14],
    levels: [
      {
        offset: 14,
        color: "high"
      },
      {
        offset: 11,
        color: "medium"
      },
      {
        offset: 9,
        color: "normal"
      },
      {
        offset: 5,
        color: "normal"
      },
      {
        offset: 3,
        color: "medium"
      },
      {
        offset: 0,
        color: "high"
      }
    ],
    hooks: {
    },
  },
  {
    name: "conductivity",
    title: "geleidbaarheid",
    description: (
      <p>Aan de geleidbaarheid kan worden afgelezen hoe zout het water is. Bij langdurige droogte kan zout kwelwater of indringend zeewater zich mengen met het water in meren en rivieren. De geleidbaarheid van water kan ook toenemen door (industriële) vervuiling. Als op een plaats voor langere tijd de geleidbaarheid gemeten wordt, dan ontstaat een beeld van de herkomst van het water. De geleidbaarheid geeft dan geen directe informatie over de zwemwaterkwaliteit, maar is wel van belang om condities te kunnen duiden. Op de Amstel staan hogere waarden in de zomer bijvoorbeeld voor een grotere aanvoer van Amstelwater.<br />
        <Link className="more" to="/over-waterkwaliteit/">Meer info</Link>
      </p>
    ),
    suffix: "µs/cm",
    scale: "scaleLinear",
    domain: [0,4000],
    tickValues: [0,1000,2000,3000,4000],
    levels: [
      {
        offset: 4000,
        color: "high"
      },
      {
        offset: 3500,
        color: "medium"
      },
      {
        offset: 3000,
        color: "normal"
      }
    ],
    hooks: {
    },
  },
  {
    name: "turbidity",
    title: "troebelheid",
    description: (
      <p>De troebelheid van het water bepaald hoeveel licht er door word gelaten wat belangrijk is voor waterplanten. Hoge troebelheid kan duiden op vervuiling of veel algengroei.<br />
        <Link className="more" to="/over-waterkwaliteit/ecologische-waterkwaliteit/">Meer info</Link>
      </p>
    ),
    suffix: "NTU",
    scale: "scaleLinear",
    domain: [0,1000],
    tickValues: [0,250,500,750,1000],
    levels: [
      {
        offset: 7500,
        color: "high"
      },
      {
        offset: 5000,
        color: "medium"
      },
      {
        offset: 2500,
        color: "normal"
      }
    ],
    hooks: {
    },
  }
]

export default parameters