import React from 'react'

import Section from '../../template/Section'

class Overstorten extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      weather : "normaal"
    }
  }

  setWeather(weather) {
    this.setState({ weather })
  }

  render() {
    const { weather } = this.state

    return (
      <Section id="overstorten" bg="light">
        <div className="columns">
          <div className="column column-width-s-12 column-width-m-4">
            <h2>Overstorten</h2>
            <p>Historisch gezien werd huishoudelijk afvalwater verzameld voor hergebruik. Vanaf 1872 werd een groot rioolnetwerk aangelegd ten tijde van de bouw van de buurten rondom het Vondelpark en de Pijp. Dit oude systeem is een gemengd rioolsysteem. Dat betekent dat huishoudelijk afvalwater en regenwater via dezelfde buis worden afgevoerd naar de rioolwaterzuivering.</p>
            <p>In stadsuitbreidingen vanaf 1930 was de bestaande riolering te klein om op aan te sluiten. Vanaf dat moment wordt een gescheiden rioolstelsel aangelegd, waar regenwater en huishoudelijk afvalwater via verschillende buizen wordt afgevoerd. Afvalwater naar de waterzuivering en regenwater naar het oppervlaktewater.</p>
            <p>Beide systemen zijn erg robuust, maar bij extreme regenval zijn er in een gemengd stelsel overstorten. Er stort dan sterk verdund onbehandeld huishoudelijk afvalwater op het oppervlaktewater. Dat is om te voorkomen dat het afvalwater in huizen omhoog komt in toiletten en gootstenen. Overstortriolen zijn te herkennen aan hun gele putdeksels. Gelukkig komen overstortcondities weinig voor, zo’n zes à tien keer per jaar. Helaas wel vaker in de zomermaanden. Via het gescheiden stelsel is de afvoer van regenwater na een extreme bui niet heel erg, omdat in feite regenwater is. Er spoelt wel vervuiling mee naar het oppervlaktewater.</p>
          </div>
          <div className="column column-width-s-12 column-width-m-8">
            <img className="clickable" onClick={()=> { this.toggleTide()}} src={`/images/overstorten-${weather}.png`} alt="" style={{marginBottom: "2rem"}} />
            <h3 className="center">
              <span className={(weather === "normaal" ? "active" : null)} onClick={()=> { this.setWeather("normaal") }}>Normaal</span>                
              <span className={(weather === "extreme-regen" ? "active" : null)} onClick={()=> { this.setWeather("extreme-regen") }}>Extreme regen</span>
            </h3>
          </div>
        </div>
      </Section>
    )
  }
}

export default Overstorten
