import fetchMunisenseJSON from '../api/munisense.js'
import fetchWaternetLocal from '../api/waternetLocal.js'
//import { hours } from '../util/datetime.js'
import parameters from './settings/parameters.js'


// function noZero(data) {
//   return data.avg > 0
// }

// function groupData(data, interval) {
//   console.log(data)

//   if (data.length === 0) return false

//   let output = [],
//       group = 0,
//       lastTime = data[0].timestamp - interval

//   for (var i = 0; i < data.length; i++) {
//     if((data[i].timestamp - lastTime) > hours(interval)) group++

//     output[group] = output[group] || []
//     output[group].push(data[i])
//     lastTime = data[i].timestamp
//   }

//   return output
// }

const queryParameterData = (s) => {
  return function getParameterData(parameter) {
    const fetchFunction = (parameter.source === 'munisense' ? fetchMunisenseJSON : fetchWaternetLocal)
    const parameterWarnings = parameters.find(p => p.name === parameter.name).warnings || []

    return fetchFunction(parameter.name, [s.start, s.end], s.interval)
      //.then(data => { return data.filter(noZero) })
      .then(data => {
        //const groupedData = groupData(data, s.interval)

        const events = parameterWarnings.reduce((events, warning) => {
          const points = warning.condition(data).map((data) => {
            const start = data[0].timestamp
            const end = data[data.length - 1].timestamp
            return { type: warning.name, start, end }
          })
          return events.concat(points)
        }, [])

        return Object.assign({}, parameter, {
          data,
          events
        })
      })
  }
}

export default queryParameterData