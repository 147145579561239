import * as d3 from 'd3'

function multiply(multiplier) {
  return function(value) {
    return value * multiplier
  }
}

export const hours = multiply(60 * 60 * 1000)

export const dm  = d3.timeFormat("%e %B")
export const dmy = d3.timeFormat("%e %B %Y")
export const hm  = d3.timeFormat("%H:%M")

