import React from 'react'
import { Link, withRouter } from 'react-router-dom'

class Nav extends React.Component {
  constructor(props){
    super(props)

    this.handleScroll = this.handleScroll.bind(this)

    this.state = {
      initial: props.initial,
      location: this.props.history.location.pathname,
      status: false,
      scrolled: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.unsubscribeFromHistory = this.props.history.listen(this.handleLocationChange)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory()
  }

  handleLocationChange = (location) => {
    this.setState({
      location: location.pathname,
      status: false
    })
  }

  handleScroll(e) {
    this.setState({
      status: false
    })

    const top = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
    if (top > 20  && this.state.scrolled === false) {
      this.setState({
        scrolled: true
      })
    } else if(top <= 20  && this.state.scrolled === true) {
      this.setState({
        scrolled: false
      })
    }
  }

  toggleNav() {
    this.setState({
      status: !this.state.status
    })
  }


  render() {
    let className = this.state.initial
    if(this.state.scrolled === true) className += " scrolled"
    if(this.state.status === true) className += " nav-toggled"

    const location = this.state.location

    return (
      <nav id="site-navigation" className={className}>
        <h1 id="site-title" onClick={this.toggleNav.bind(this)}>
          <span>Amsterdecks</span>
        </h1>

        <ul>
          <li><Link className={location === "/" ? "current" : null} to="/">Home</Link></li>
          <li><Link className={location === "/over-waterkwaliteit/" ? "current" : null} to="/over-waterkwaliteit/">Over Waterkwaliteit</Link>
            <ul>
              <li><Link className={location === "/over-waterkwaliteit/water-in-amsterdam/" ? "current" : null} to="/over-waterkwaliteit/water-in-amsterdam/">Water in Amsterdam</Link></li>
              <li><Link className={location === "/over-waterkwaliteit/waterverontreiniging/" ? "current" : null} to="/over-waterkwaliteit/waterverontreiniging/">Waterverontreiniging</Link></li>
              <li><Link className={location === "/over-waterkwaliteit/riolering/" ? "current" : null} to="/over-waterkwaliteit/riolering/">Riolering</Link></li>
              <li><Link className={location === "/over-waterkwaliteit/ecologische-waterkwaliteit/" ? "current" : null} to="/over-waterkwaliteit/ecologische-waterkwaliteit/">Ecologische waterkwaliteit</Link></li>
              <li><Link className={location === "/over-waterkwaliteit/zwemwaterkwaliteit/" ? "current" : null} to="/over-waterkwaliteit/zwemwaterkwaliteit/">Zwemwaterkwaliteit</Link></li>
            </ul>
          </li>
          <li><Link className={location === "/over-amsterdecks/" ? "current" : null} to="/over-amsterdecks/">Over Amsterdecks</Link></li>
        </ul>
      </nav>
    )
  }
}

Nav = withRouter(Nav)

export default Nav
