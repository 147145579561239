import React from 'react'
import { connect } from 'react-redux'
import { setLatest } from '../actions'

import { roundBy } from '../util/utility'


class ActiveDot extends React.Component {

  componentWillMount() {
    this.props.dispatch(setLatest(false))
  }

  render() {
    const { cx, cy, value, suffix, parameter, activeChart } = this.props

    if (parameter !== activeChart) return null

    const orientation = (cx > window.innerWidth/2 ? 'left' : 'right')
    const x = (orientation === 'left' ? cx - 18 : cx + 18)

    const content = (value ? <span><strong>{roundBy(value, 2)}</strong> {suffix}</span> : 'geen data')

    return (
      <foreignObject x={x} y={cy-12} width="10" height="10">
        <div className={`tooltip data-tooltip ${orientation}`} xmlns="http://www.w3.org/1999/xhtml">{content}</div>
      </foreignObject>
    )
  }
}

const mapStateToProps = state => ({
  activeChart: state.activeChart,
})
ActiveDot = connect(mapStateToProps)(ActiveDot)

export default ActiveDot