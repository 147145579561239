const locations = [
  {
    name: "nieuwe-meer",
    title: "Nieuwe Meer",
    dataStart: new Date(1562317200000),
    parameters: [
      { name: 'dissolvedoxygen_temperature', source: 'munisense' },
      { name: 'dissolvedoxygen', source: 'munisense' },
      { name: 'conductivity', source: 'munisense' },
      { name: 'ph', source: 'munisense' },
      { name: 'turbidity', source: 'munisense' }
    ]
  },
  {
    name: "berlagebrug",
    title: "Berlagebrug",
    parameters: [
      { name: 'dissolvedoxygen_temperature', source: 'waternet' },
      { name: 'dissolvedoxygen', source: 'waternet' },
      { name: 'turbidity', source: 'waternet' },
      { name: 'conductivity', source: 'waternet' }
    ]
  }
]

export default locations