import React from 'react'

import Section from './Section'

const Footer = (props) => {
  return (
    <Section id="site-footer" bg="grey">
      <div className="columns">
        <div className="column column-width-s-12 column-width-m-6">
          <h2>Partners</h2>
          <p>Amsterdecks is een project van <a href="http://www.rademacherdevries.com/" target="_blank" rel="noopener noreferrer">Rademacher de Vries Architecten</a> en <a href="https://waag.org/" target="_blank" rel="noopener noreferrer">Waag</a>, met steun van de <a href="https://www.amsterdam.nl/" target="_blank" rel="noopener noreferrer">Gemeente Amsterdam</a>/<a href="https://www.amsterdamsebos.nl/" target="_blank" rel="noopener noreferrer">Amsterdamse Bos</a>, <a href="https://www.waternet.nl/" target="_blank" rel="noopener noreferrer">Waterschap Amstel, Gooi en Vecht</a>, <a href="https://www.rijnland.net/" target="_blank" rel="noopener noreferrer">Hoogheemraadschap Rijnland</a>, <a href="https://www.doen.nl/" target="_blank" rel="noopener noreferrer">Stichting DOEN</a> en <a href="https://stimuleringsfonds.nl/" rel="noopener noreferrer">Stimuleringsfonds Creatieve Industrie</a>. Alle rechten voorbehouden.</p>
          <h2>Team</h2>
          <p>
            <strong>RDVA:</strong> Christopher de Vries, David Rademacher<br/>
            <strong>Waag:</strong> Alain Otjens, Dave Gönner, Emma Pareschi, Lotte Geeraedts, Taco van Dijk, Ilyas bin Sarib, Coen Bergman, Tom Demeyer, Job Spierings, Martin Risseeuw, Ivo de Kler<br/>
            <strong>Waterschap Amstel, Gooi en Vecht:</strong> Maarten Ouboter, Ben Staring, Hans van der Pal, Jan Willem Voort, Eljakim Koopman, Thomas Staverman, Kees van der Lugt, Liesbeth Hersbach<br/>
            <strong>Munisense:</strong> Hans Mulder, Joost Pastoor
          </p>
        </div>
        <div className="column column-width-s-12 column-width-m-6">
          <h2>Contact</h2>
          <p>De essentie van een duurzame Liquid Commons is een brede maatschappelijke participatie. In 2019 beginnen we met de aanleg van Amsterdecks en we zoeken daarom allianties en samenwerkingsverbanden door heel Amsterdam.</p>
          <p>Wij nodigen buurtbewoners, beleidsmakers, investeerders, ondernemers en iedereen die wil bijdragen aan een schone en duurzame toekomst voor Amsterdam uit om deel uit te maken van het project.</p>
          <p>Wilt u een Amsterdeck bij u in de buurt? Neem contact met ons op!</p>
          <p><a className="button" href="mailto:cdv@rademacherdevries.com">Stuur een email</a></p>
        </div>
      </div>
    </Section>
  )
}

export default Footer