import * as d3 from 'd3'
import { csv as d3CSV } from 'd3-request'
import { toFixed } from '../util/utility.js'

const parseDateTime  = d3.timeParse("%d-%m-%y %H:%M:%S")

const fetchWaternetLocal = (name, timeDomain, interval) => {
  return new Promise((resolve, reject) => {

    const start = new Date(timeDomain[0])
    const end = new Date(timeDomain[1])

    const factor = (name === "conductivity" ? 1000 : 1)

    // get one interval more so chart is filled to end
    end.setHours(end.getHours() + interval)

    return d3CSV("data/" + name + ".csv", function(error, csvData) {
      if(error) reject(error)



      var dataPrepared = csvData
        .filter(function(d){
          var datetime = parseDateTime(d.datetime)
          return datetime >= start && datetime < end
        })
        .map(function(d){

          var datetime = parseDateTime(d.datetime)

          if(interval > 24) {
            const days = interval / 24
            datetime.setDate(toFixed(datetime.getDate() / days, 0) * days)
            datetime.setHours(0)
          } else {
            datetime.setHours(toFixed(datetime.getHours() / interval, 0) * interval)
          }

          datetime.setMinutes(0)
          datetime.setSeconds(0)

          return {
            datetime: datetime,
            value: d.value
          }
        })

      var data = d3.nest()
        .key(function(d) { return d.datetime })
        .rollup(function(v) {
          v = v.map(function(d) { return Number(d.value) }).sort(d3.ascending)

          return {
            avg: d3.quantile(v, 0.50),
            minmax: [d3.quantile(v, 0.05), d3.quantile(v, 0.95)]
          }
        })
        .entries(dataPrepared)
        .map(function(d) {
          console.log(d.key)
          return {
            //timestamp: new Date(d.key),
            timestamp: d.key,
            avg: Number(d.value.avg * factor),
            minmax: [Number(d.value.minmax[0] * factor), Number(d.value.minmax[1] * factor)]
          }
        })
      resolve(data)
    })
  })
}

export default fetchWaternetLocal