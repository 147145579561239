import React from 'react'

class Stroming extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      tide : "eb"
    }
  }

  componentDidMount(){
    window.objectFitPolyfill()

    window.addEventListener('click', this.playVideo);

    // const stromingVideo = document.getElementById("stroming-video");
    // stromingVideo.play();
  }

  componentWillUnmount(){
    window.removeEventListener('click', this.playVideo);
  }

  componentDidUpdate(){
    window.objectFitPolyfill()
  }

  toggleTide() {
    const tide = (this.state.tide === "eb" ? "vloed" : "eb")
    this.setState({ tide })
  }

  setTide(tide) {
    this.setState({ tide })

    this.playVideo()
  }

  playVideo() {
    const stromingVideo = document.getElementById("stroming-video");
    stromingVideo.play();
  }


  render() {
    const { tide } = this.state

    return (
      <section id="stroming" className="full-imagery" style={{ background: '#172932' }}>
        <video id="stroming-video" src="/video/stroming.mp4" data-object-fit="cover" muted autoPlay controls loop />
        <div className="text-panel middle">
          <h2>Stroming</h2>
          <div className="columns">
            <div className="column column-width-s-12 column-width-m-6">
              <p>Is het u al eens opgevallen dat water in Amsterdam niet altijd in dezelfde richting stroomt?</p>
              <p>De waterstroming rondom Amsterdam is alles behalve natuurlijk. Een heel netwerk van gemalen, sluizen en spuien regelt wanneer en waar water stroomt. De twee meest bepalende elementen in dit systeem zijn het Amsterdam-Rijnkanaal en de spuisluizen bij IJmuiden. Het kanaal zorgt voor een grote toevoer vanuit de Rijn (in verhouding veel groter dan de invloed van de Amstel). Omdat Amsterdam onder NAP ligt kunnen de spuien bij IJmuiden alleen open bij eb. Bij vloed gaan de spuien dicht. Omdat de Rijn doorstroomt en tegen de dichte spuien stoot, komt een omgekeerde stroming op gang. De stroomrichting in Amsterdam verandert dan van richting. Dit gebeurt elke dag. Dit zogenaamde “pseudo-getij” zorgt in principe dat het water doorstroomt en is dus goed voor de kwaliteit van het water. Onder ongunstige omstandigheden kan het hierdoor echter langer in de stad blijven hangen.</p>
            </div>
            <div className="column column-width-s-12 column-width-m-6">
              <h3 className="center">
                <span className={(tide === "eb" ? "active" : null)} onClick={()=> { this.setTide("eb") }}>Eb</span>                
                <span className={(tide === "vloed" ? "active" : null)} onClick={()=> { this.setTide("vloed") }}>Vloed</span>
              </h3>
              <img className="clickable" onClick={()=> { this.toggleTide()}} src={`/images/stroming-${tide}.png`} alt="" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Stroming
