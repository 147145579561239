import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './css/styles.scss'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducer from './reducers'

import Home                      from './Home'
import OverWaterkwaliteit        from './OverWaterkwaliteit'
import WaterInAmsterdam          from './OverWaterkwaliteit/WaterInAmsterdam'
import Waterverontreiniging      from './OverWaterkwaliteit/Waterverontreiniging'
import Riolering                 from './OverWaterkwaliteit/Riolering'
import EcologischeWaterkwaliteit from './OverWaterkwaliteit/EcologischeWaterkwaliteit'
import Zwemwaterkwaliteit        from './OverWaterkwaliteit/Zwemwaterkwaliteit'
import OverAmsterdecks           from './OverAmsterdecks'

const store = createStore(reducer)

class Website extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div id="site-container">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/over-amsterdecks/" component={OverAmsterdecks} />
              <Switch>
                <Route exact path="/over-waterkwaliteit/" component={OverWaterkwaliteit} />
                <Route path="/over-waterkwaliteit/water-in-amsterdam" component={WaterInAmsterdam} />
                <Route path="/over-waterkwaliteit/waterverontreiniging" component={Waterverontreiniging} />
                <Route path="/over-waterkwaliteit/riolering" component={Riolering} />
                <Route path="/over-waterkwaliteit/ecologische-waterkwaliteit" component={EcologischeWaterkwaliteit} />
                <Route path="/over-waterkwaliteit/zwemwaterkwaliteit" component={Zwemwaterkwaliteit} />
              </Switch>
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }
}

ReactDOM.render(<Website />, document.getElementById('root'))
