import React from 'react'
import Moment from "moment"
import { extendMoment } from 'moment-range'
import localization from "moment/locale/nl-be"

const moment = extendMoment(Moment)

const DateTooltip = ({ active, payload, label, interval }) => {

  if (active) {
    moment().locale("nl", localization)

    const timeFormat = 'HH:mm'

    const endTime = new Date(payload[0].value)
    const beginTime = new Date(endTime)
    beginTime.setHours(beginTime.getHours() - interval)

    //console.log(endTime)

    if(endTime.getDate() !== beginTime.getDate()) {
      const dateFormat = (endTime.getYear() !== beginTime.getYear() ? 'D MMM YY' : 'D MMM')

      return (
        <div className="tooltip date-tooltip">
          { moment(beginTime).format(dateFormat) } <strong>{ moment(beginTime).format(timeFormat) }</strong><br />
          { moment(endTime).format(dateFormat) } <strong>{ moment(endTime).format(timeFormat) }</strong>
        </div>
      )
    } else {
      return (
        <div className="tooltip date-tooltip">
          { moment(beginTime).format("D MMM") }<br />
          <strong>{ moment(beginTime).format(timeFormat) }-{ moment(endTime).format(timeFormat) }</strong>
        </div>
      )
    }
  }

  return null
}

export default DateTooltip