import React from 'react'
import { Link } from 'react-router-dom'

import Navigation from './template/Navigation'
import Footer from './template/Footer'

import WaterQualityVisualization from './WaterQualityVisualization'


class Home extends React.Component {
  render() {
    return (
      <div id="home">
        <Navigation initial="hidden" />

        <header id="page-header" className="bg-image-header">
          <div className="image" style={{ backgroundImage: 'url("/images/header-home.jpg")' }}></div>
          <div className="text">
            <div className="wrapper">
              <h1 className="title">Amsterdecks</h1>
              <p className="introduction">Amsterdecks zijn openbare vlonders die informatie over de waterkwaliteit tonen. Door de actuele watercondities te tonen en de mogelijke oorsprong van verontreinigingen toe te lichten, bieden we betrokken burgers handelingsperspectief om te participeren in een schoner Amsterdam.</p>
            </div>
          </div>
        </header>

        <WaterQualityVisualization />

        <section id="over-waterkwaliteit" className="bg-light bg-image" style={{ backgroundImage: 'url("/images/bg-map.svg")' }}>
          <div className="wrapper">
            <div className="columns">
              <div className="column column-width-s-12 column-width-m-8">
                <h2>Waterkwaliteit</h2>
                <p>Water is van iedereen en tegelijkertijd van niemand alléén. Elk type gebruik van water vergt een bepaalde waterkwaliteit die vaak verandert door dit gebruik. Als men bijvoorbeeld een zwembad vult met drinkwater, is het na een dag zwemmen niet schoon genoeg meer om te drinken. Een ander voorbeeld is een fabriek, waar water tijdens het productieproces vervuild raakt. Op deze manier verbindt water iedereen via onze alledaagse handelingen in een groot systeem van onderlinge afhankelijkheden. Deze afhankelijkheden kunnen zowel synergieën als belangenconflicten opleveren.</p>
                <p className="actions">
                  <Link className="button" to="/over-waterkwaliteit/">Meer over waterkwaliteit</Link>
                </p>
              </div>
            </div>
          </div>
        </section> 

        <section className="bg-dark">
          <div className="wrapper">
            <div className="columns">
              <div className="column column-width-s-12 column-width-m-8">
                <h2>Over Amsterdecks</h2>
                <p>Amsterdecks brengt water dichtbij met een slim zwemvlonder dat de waterkwaliteit direct zichtbaar maakt. Vier cilinders op de vlonder geven <em>real-time</em> de meetwaarden weer van vier watersensoren die onder de vlonder hangen. Op deze website vind je meer informatie over en visualisatie van de meetwaarden.</p>
                <p className="actions">
                  <Link className="button" to="/over-amsterdecks/">Meer over Amsterdecks</Link>
                </p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
       
      </div>
    )
  }
}

export default Home
