import React from 'react'

const Header = (props) => {
  return (
    <header id="page-header" className={( !!props.image ? "bg-image-header" : "bg-color")}>
      { (!!props.image ? <div className="image" style={{ backgroundImage: `url(${props.image})` }} /> : null) }

      <div className="text">
        <div className="wrapper">
          {props.children}
        </div>
      </div>
    </header>
  )
}

export default Header