import { combineReducers } from 'redux'
import { defaultLocation, defaultPreset } from './WaterQualityVisualization/settings/defaults'

//'nieuwe-meer'
const location = (state = defaultLocation, action) => {
  switch (action.type) {
    case 'SET_LOCATION':
      return action.location;
    default:
      return state;
  }
}

const chartSettings = (state = { preset: defaultPreset }, action) => {
  switch (action.type) {
    case 'SET_CHART_SETTINGS':
      return action.chartSettings;
    default:
      return state;
  }
}

const data = (state = [], action) => {
  switch (action.type) {
    case 'SET_DATA':
      return action.data;
    default:
      return state;
  }
}

const domainSettings = (state = { timeFormatter: null, ticks: null, domain: [null, null] }, action) => {
  switch (action.type) {
    case 'SET_DOMAIN_SETTINGS':
      return action.domainSettings;
    default:
      return state;
  }
}

const brushSettings = (state = { startIndex: null, endIndex: null }, action) => {
  switch (action.type) {
    case 'SET_BRUSH_SETTINGS':
      return action.brushSettings;
    default:
      return state;
  }
}

const activeChart = (state = null, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_CHART':
      return action.activeChart;
    default:
      return state;
  }
}

const infoBox = (state = null, action) => {
  switch (action.type) {
    case 'SET_INFO_BOX':
      return action.infoBox;
    default:
      return state;
  }
}

const latest = (state = true, action) => {
  switch (action.type) {
    case 'SET_LATEST':
      return action.latest;
    default:
      return state;
  }
}


const combinedReducer = combineReducers({
  location,
  chartSettings,
  data,
  domainSettings,
  brushSettings,
  activeChart,
  infoBox,
  latest
})

export default combinedReducer