import * as d3 from 'd3'

d3.timeFormatDefaultLocale({
  "dateTime": "%a %e %B %Y %T",
  "date": "%d-%m-%Y",
  "time": "%H:%M:%S",
  "periods": ["AM", "PM"],
  "days": ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
  "shortDays": ["zo", "ma", "di", "wo", "do", "vr", "za"],
  "months": ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
  "shortMonths": ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]
})

const presets = [
  {
    name: "day",
    title: "dag",
    days: 1,
    load: 14,
    interval: 1
  },
  {
    name: "week",
    title: "week",
    days: 7,
    load: 6,
    interval: 6
  },
  {
    name: "month",
    title: "maand",
    days: 31,
    load: 6,
    interval: 12
  },
  {
    name: "year",
    title: "jaar",
    days: 365,
    load: 3,
    interval: 24
  }
]

export default presets