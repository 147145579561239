import React from 'react'

//import { roundBy } from "./../util/utility"

import ReactMapGL, { Marker } from 'react-map-gl';
import { mapStyle } from "./settings/mapStyle"


class Legend extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      viewport: {
        latitude: 52.332952,
        longitude: 4.846400,
        zoom: 11
      }
    }
  }

  onViewportChange = viewport => { 
    const {width, height, ...etc} = viewport
    this.setState({viewport: etc})
  } 

  render() {
    //const { locationParameters, data, parameters } = this.props

    return (
      <div className="columns" style={{ padding: "0 2rem" }}>
        <div className="column column-width-s-12 column-width-m-7 column-width-l-8">
          <div id="map">
            <ReactMapGL width="100%" height="100%" {...this.state.viewport} onViewportChange={viewport => this.onViewportChange(viewport)} mapStyle={mapStyle}>
              <Marker latitude={52.332952} longitude={4.846400} offsetLeft={-10} offsetTop={-20}>
                <h3>AmsterDeck Nieuwe Meer</h3>
                {/* <table>
                  <tbody>
                  { locationParameters.map(parameter => {
                      const parameterData = data.find(p => p.name === parameter.name).data || []
                      const parameterSettings = parameters.find(p => p.name === parameter.name) || []

                      return <tr key={parameter.name}>
                        <th>{parameterSettings.title}</th>
                        <td><strong>{ roundBy(parameterData[parameterData.length - 1].avg, 2) }</strong> {parameterSettings.suffix}</td>
                        </tr>
                    })
                  }
                  </tbody>
                </table> */}
              </Marker>
            </ReactMapGL>
          </div>
          <h3>Wat is de situatie van water in de Nieuwe Meer?</h3>
          <p>De Nieuwe Meer is onderdeel van de boezem van het Hoogheemraadschap van Rijnland. Aan de westzijde in de Oeverlanden zijn twee officiële zwemwaterlocaties. Om te voorkomen dat door concentraties aan stikstof en fosfaat blauwalgen ontstaan, ligt er een beluchtingsinstallatie. In de Nieuwe Meer wordt ook veel gevaren, zowel scheepvaart als recreatievaart, en gewoond aan het water.</p>
          <h3>Welke omgevingsfactoren zijn het belangrijkst?</h3>
          <p>Op de Nieuwe Meer storten geen riolen over. De kans op slechte bacteriologische waterkwaliteit is dan ook gering. Het belangrijkste gevaar voor zwemmers (buiten de officiële zwemwaterlocaties) is de scheep- en recreatievaart.</p>
          <h3>Wat doet de beluchtingsinstallatie?</h3>
          <p>Door lucht te pompen in slangen die op de bodem van de plas liggen, ontstaan luchtbellenschermen. Deze brengen het water in beweging en zorgen voor stroming. De blauwalgen, die in de bovenste waterlaag voldoende licht hebben om te groeien, worden door de stroming meegevoerd naar de diepe en donkere delen van de plas. In het donker kunnen ze niet meer groeien en sterven ze af.</p>
        </div>
        <div className="column column-width-s-12 column-width-m-5 column-width-l-4">
          <h3>Wat zie ik?</h3>
          <p>Hierboven zie je meetgegevens van temperatuur, zuurstof, geleidbaarheid, ph en troebelheid (turbiditeit). Voor elke parameter geeft de stippellijn het gemmidelde aan en de kleurband de laagste en hoogste waarde. Hoe breder die kleurband, hoe groter de variatie in die periode.</p>
          <p>Wanneer de waarden naar geel en rood gaan bestaat er kans op slechtere waterkwaliteit. Klik op uitroeptekens om meer te weten te komen over deze risico's en de mogelijke oorzaken.</p>
        </div>
      </div>
    )
  }
}


export default Legend