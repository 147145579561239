import React from 'react'
import * as d3 from 'd3'
import { connect } from 'react-redux'
import { setLocation, setChartSettings, setData, setDomainSettings, setBrushSettings, setInfoBox } from '../actions'

import queryParameterData from './queryParameterData.js'
import getPresetSettings from './getPresetSettings.js'

import { chartHeight, chartPadding } from './settings/defaults'
import locations from './settings/locations.js'
import presets from './settings/presets.js'
import parameters from './settings/parameters.js'

import InfoBox from './InfoBox.js'

import Legend from './Legend'
import DateTooltip from './DateTooltip'


import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  Brush,
} from "recharts"
import ParameterChart from './ParameterChart.js'


class WaterQualityVisualization extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    //this.handleLocationChange = this.handleLocationChange.bind(this)
    this.handlePresetChange = this.handlePresetChange.bind(this)
  }

  // LIFE CYCLE METHODS
  componentDidMount() {

    if(this.props.data.length === 0) {
      const settings = getPresetSettings(this.props.settings.preset, this.props.location)

      this.props.dispatch(setChartSettings(settings))

      this.updateCharts(settings)
    }
  }


  // CHANGE SETTINGS
  handleLocationChange(e) {
    const location = e.target.value

    //this.setState({ locationParameters: locations.find(l => l.name === location).parameters })
    this.props.dispatch(setLocation(location))
    this.updateCharts(this.props.settings)
  }

  handlePresetChange(e) {
    const settings = getPresetSettings(e.target.value, this.props.location)

    this.props.dispatch(setChartSettings(settings))
    this.updateCharts(settings)
  }

  handleRangeChange(indexes) {
    this.props.dispatch(setInfoBox(null))

    const start = new Date(this.props.data[0].data[indexes['startIndex']].timestamp).getTime()
    const end = new Date(this.props.data[0].data[indexes['endIndex']].timestamp).getTime()
    const domain = d3.scaleTime().domain([start, end])

    this.props.dispatch(setBrushSettings(indexes))
    this.setTickSettings(start, end, domain)
  }


  // UPDATE
  updateCharts(settings) {
    this.props.dispatch(setInfoBox(null))
    this.props.dispatch(setData([]))

    const locationParameters = locations.find(l => l.name === this.props.location).parameters
    Promise.all(locationParameters.map(queryParameterData(settings)))
      .then(data => {
        this.setState({ error: null })
        this.setDomain(data)
        this.props.dispatch(setData(data))
      })
      .catch(error => {
        console.warn(error)
        this.setState({ error })
      })
  }

  setDomain(data) {
    const { settings } = this.props
    const sampleData = data[0].data

    const visibleDataPoints = settings.days * 24/settings.interval

    const startIndex = (visibleDataPoints >= sampleData.length ? 0 : sampleData.length - visibleDataPoints)
    const endIndex = sampleData.length-1

    const domain = d3.scaleTime().domain([sampleData[startIndex].timestamp, sampleData[endIndex].timestamp])

    this.props.dispatch(setBrushSettings({ startIndex, endIndex }))
    this.setTickSettings(sampleData[startIndex].timestamp, sampleData[endIndex].timestamp, domain)
  }

  setTickSettings(start, end, domain) {
    const range = end - start

    var ticks, timeFormat
    switch (true) {
      case (range <= 86400000):
          //console.log('day')
          timeFormat = '%d %b %H:%M'
          ticks = domain.ticks(d3.timeHour.every(1))
        break;
      case (range <= 604800000):
          //console.log('week')
          timeFormat = '%d %b'
          ticks = domain.ticks(d3.timeDay.every(1))
        break;
      default:
          //console.log('month')
          timeFormat = '%d %b'
          ticks = domain.ticks(d3.timeMonday.every(1))
          ticks.unshift(new Date(start))
          ticks.push(new Date(end))
          break;
    }

    const timeFormatter = tick => d3.timeFormat(timeFormat)(new Date(tick))

    this.props.dispatch(setDomainSettings({
      timeFormatter,
      ticks,
      domain: [start, end]
    }))
  }


  // RENDER
  renderParameterCharts(data, locationParameters) {
    if(data.length > 0) {
      const { timeFormatter, ticks } = this.props.domainSettings
      const { startIndex, endIndex } = this.props.brushSettings

      // ALL CHART PARTS FOR ALL THE PARAMETERS FOR THE CURRENT LOCATION
      const ParameterCharts = locationParameters.map((parameter, i) => {
        const parameterData = data.find(p => p.name === parameter.name) || []
        const parameterSettings = parameters.find(p => p.name === parameter.name) || []

        return <ParameterChart key={parameter.name} data={parameterData.data} events={parameterData.events} settings={parameterSettings} />
      })

      return (
        <div onClick={ ()=> { this.props.dispatch(setInfoBox(null)) }}>
          <ResponsiveContainer width="100%" height={50} >
            <ComposedChart data={data[0].data} syncId="wqv" margin={{top: 0, right: chartPadding, bottom: 0, left: chartPadding}}>
              <Line dataKey="timestamp" style={{ opacity: 0 }} dot={false} activeDot={false} />
              {/* <Line dataKey="avg" style={{ opacity: 1 }} dot={false} activeDot={false} /> */}
              <Tooltip animationDuration={0} content={<DateTooltip interval={this.props.settings.interval} />} cursor={false} offset={0} />
              <Brush dataKey="timestamp" height={25} fill="rgba(0,0,0,0.1)" stroke="#FFF" travellerWidth={10} startIndex={startIndex} endIndex={endIndex} tickFormatter={timeFormatter} onChange={indexes => { this.handleRangeChange(indexes) }} />
              <XAxis interval="preserveStartEnd" mirror={true} stroke="#FFF" dataKey="timestamp" scale="time" type="number" ticks={ticks} tickFormatter={timeFormatter} tickMargin={5} domain={['dataMin', 'dataMax']} padding={{ left:4, right:4 }} />
            </ComposedChart>
          </ResponsiveContainer>
          {ParameterCharts}
        </div>
      )
    } else {
      return (
        <div className="loading" style={{ height: 50 + locationParameters.length * chartHeight }}>
          <div className="loader"><div></div><div></div><div></div><div></div></div>
        </div>
      )
    }
  }


  render() {
    const { settings, data, infoBox, location } = this.props
    const locationParameters = locations.find(l => l.name === location).parameters

    // CONTROL OPTIONS
    // const locationOptions = locations.map((l) => {
    //   return (
    //     <span key={l.name}>
    //       <input id={`location-${l.name}`} name="location" type="radio" value={l.name} onChange={this.handleLocationChange} checked={location === l.name}/><label htmlFor={`location-${l.name}`}>{l.title}</label>
    //     </span>
    //   )
    // })

    const presetOptions = presets.map((p) => {
      return (
        <span key={p.name}>
          <input id={`time-preset-${p.name}`} name="time-preset" type="radio" value={p.name} onChange={this.handlePresetChange} checked={settings.preset === p.name} /><label htmlFor={`time-preset-${p.name}`}>{p.title}</label>
        </span>
      )
    })

    return (
      <section id="water-quality-visualization" className="bg-dark">
        <div className="wrapper">
          <div id="controls" style={{ padding: `0 2rem` }}>
            <h3>AmsterDeck Nieuwe Meer</h3>
            {/* <div id="control-source" className="control">{locationOptions}</div> */}
            <div id="control-time-preset" className="control">{presetOptions}</div>
          </div>
          <div id="charts">
            { this.renderParameterCharts(data, locationParameters) }
          </div>
          { data.length > 0 ? <Legend locationParameters={locationParameters} data={data} parameters={parameters} /> : null }
        </div>
        { !!infoBox ? <InfoBox settings={infoBox} /> : null }
      </section>
    )
  }
}


const mapStateToProps = (state) => ({
  location: state.location,
  settings: state.chartSettings,
  data: state.data,
  domainSettings: state.domainSettings,
  brushSettings: state.brushSettings,
  infoBox: state.infoBox,
})
WaterQualityVisualization = connect(mapStateToProps)(WaterQualityVisualization)

export default WaterQualityVisualization
