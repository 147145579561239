import React from 'react'
import { connect } from 'react-redux'
import { setInfoBox } from '../actions'

class InfoBox extends React.Component {

  render() {
    return (
      <div className={ "info-box " + this.props.settings.className } style={this.props.settings.style}>
        <span className="arrow"></span>
        <span className="close" onClick={() => { this.props.dispatch(setInfoBox(null)) }}>✕</span>
        {this.props.settings.content}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  infoBox: state.infoBox
})
InfoBox = connect(mapStateToProps)(InfoBox)

export default InfoBox