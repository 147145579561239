import React from 'react'

class InVogelvlucht extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      active : "start"
    }
  }

  componentDidMount(){
    window.objectFitPolyfill()
  }

  componentDidUpdate(){
    window.objectFitPolyfill()
  }

  setActive(part) {
    const active = (this.state.active === part ? "start" : part)

    this.setState({ active })
  }


  render() {
    const { active } = this.state

    return (
      <section id="in-vogelvlucht" className="image-toggle">
        <img src={`/images/vogelvlucht-${active}.jpg`} alt="" data-object-fit="cover" />

        <div className="text-panel right">
          <h2 onClick={()=> this.setActive("start")}>Hydrologisch systeem van Amsterdam</h2>

          <ul className="toggles">

            <li className={(active === "start" ? "active" : null)}>
              {/* <h3 onClick={()=> this.setActive("systeem")}>Systeem</h3> */}
              <div className="toggle-content">
                <p>Een momentopname maken van de lokale waterkwaliteit is niet moeilijk. Een coherent beeld vormen van de waterkwaliteit van een stad is echter een complexe aangelegenheid en omvat oneindig veel uitzonderingen en nuances.</p>
                <p>Om toch een beeld te krijgen van de waterkwaliteit, kunnen we naar het hydrologische systeem van Amsterdam kijken en welke verontreinigingen erin voorkomen. We zullen op deze site kijken naar de stroming, de rioolwaterzuiveringsinstallaties, de overstorten en de uitspoeling door bepaalde types grondgebruik.</p>
              </div>
            </li>

            <li className={(active === "overstorten" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("overstorten")}>Overstorten</h3>
              <div className="toggle-content">
                <p>Bij zware regenval kan de capaciteit van het rioolsysteem het soms niet aan en moet via een noodvoorziening water worden geloosd op het oppervlaktewater. Dit is dan sterk verdund ongezuiverd rioolwater dat veel schadelijke bacteriën bevat. Dergelijke situaties komen alleen voor bij extreme regenval wat vijf tot tien keer per jaar gebeurt. Op de kaart is te zien dat overstorten zich voornamelijk in het centrum bevinden, omdat daar een gemengd rioolsysteem ligt. Na een overstortmoment reinigt het water zichzelf binnen vier à vijf dagen.</p>
              </div>
            </li>

            <li className={(active === "rzwi" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("rzwi")}>RZWI</h3>
              <div className="toggle-content">
                <p>Al het rioolwater in Amsterdam wordt naar twee waterzuiveringen in de Amsterdamse haven getransporteerd. Daar wordt het gereinigd, worden bruikbare stoffen onttrokken en wordt het uiteindelijk geloosd in het IJ. Hoewel het water uit een RWZI wordt gezuiverd, is de zuivering niet volledig. In feite werkt het als een enorme riooloverstort. Hoe meer toevoer naar de RWZI, hoe sneller die moet werken en hoe meer verontreiniging in de uitstroom van de RWZI komt. Omdat er bovenstroom op de Amstel drie RWZI’s zijn (bij Uithoorn, De Ronde Venen en Amstelveen), zorgen deze nog voor een aanzienlijke verontreiniging van het water dat via de Amstel naar het centrum stroomt.</p>
              </div>
            </li>

            <li className={(active === "stroming" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("stroming")}>Stroming</h3>
              <div className="toggle-content">
                <p>Water transporteert. Het beweegt en brengt allerlei materiaal met zich mee. De concentratie daarvan bepaalt dat we het soms verontreinigd noemen, terwijl dezelfde stoffen in lagere concentraties juist nuttig kunnen zijn. Het gaat dus om een goede balans.</p>
                <p>In het verleden werden de grachten mechanisch gespoeld, dat gebeurt echter sinds 2010 niet meer. Op dit moment zijn de twee meest invloedrijke factoren de stroming van het Amsterdam-Rijnkanaal en de spuisluizen bij IJmuiden. Wanneer water goed doorstroomt worden de verontreiniging sneller naar zee verplaatst.</p>
              </div>
            </li>

            <li className={(active === "grondgebruik" ? "active" : null)}>
              <h3 onClick={()=> this.setActive("grondgebruik")}>Grondgebruik</h3>
              <div className="toggle-content">
                <p>Het grootste oppervlak van het Waterschap Amstel, Gooi en Vecht wordt ingenomen door landbouw; ongeveer 56% . Regenval spoelt meststoffen en pesticiden uit weilanden in poldersloten, die vervolgens naar de rivieren stromen. Afhankelijk van het landbouwbeleid kan dergelijke verontreiniging erg nadelig zijn voor de waterkwaliteit en leiden tot eutrofiëring. Elk grondgebruik heeft een specifiek profiel van verontreiniging.</p>
              </div>
            </li>

          </ul>
        </div>
      </section>
    )
  }
}

export default InVogelvlucht
