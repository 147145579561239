import React from 'react'

import Navigation from './Navigation'
import Footer from './Footer'

class Page extends React.Component {
  constructor(props){
    super()

  }

  componentDidMount() {
    window.scrollTo(0,0)
  }


  render() {

    return (
      <div>
        <Navigation initial="visible" />
        {this.props.children}
        <Footer />
      </div>
    )
  }
}

export default Page
