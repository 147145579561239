import React from 'react'

import Page from '../template/Page'
import Header from '../template/Header'
import Section from '../template/Section'

class Zwemwaterkwaliteit extends React.Component {
  render() {
    return (
      <Page>
        <Header image="/images/header-zwemwaterkwaliteit.jpg">
          <h1>Zwemwaterkwaliteit</h1>
        </Header>

        <Section bg="light">
          <div className="columns" style={{ justifyContent: "center" }}>
            <div className="column column-width-s-12 column-width-m-5">
              <p>De zwemwaterkwaliteit wordt vooral bepaald door de temperatuur en door de aanwezigheid van blauwalg en de twee zwemwaterbacteriën; Escherichia coli (E.coli) en Intestinale enterococcen. De temperatuur is goed real-time te meten, maar de aanwezigheid van de bacteriën vaststellen is ingewikkelder. Daarvoor moet een watermonster op kweek worden gezet. Na twee dagen wordt in het laboratorium zichtbaar hoeveel bacteriën het water bevat. Deze vertraging van twee dagen maakt het lastig de huidige waterkwaliteit te beoordelen.</p>
              <p>Daarnaast kunnen nog allerlei extra factoren de kwaliteit van het zwemwater beïnvloeden: rommel in het water, scheepvaart, dode vissen of vogels, of een sterke stroming in de buurt van gemalen en sluizen.</p>
              <h3>Waar vind ik veilig zwemwater?</h3>
              <p>Via de app <em>Zwemwater</em> (<a href="https://play.google.com/store/apps/details?id=nl.yellowbytes.zwemwaterapp&hl=nl" target="_blank" rel="noopener noreferrer">Android</a> / <a href="https://apps.apple.com/nl/app/zwemwater/id835572632" target="_blank" rel="noopener noreferrer">iOS</a>) en op <a href="https://www.zwemwater.nl/" target="_blank" rel="noopener noreferrer">zwemwater.nl</a> vind je een overzicht van 800 officiële zwemplekken in Nederland. Op deze plekken wordt de waterkwaliteit goed gecontroleerd, met name op de aanwezigheid van bacteriën en blauwalg.</p>
            </div>
            <div className="column column-width-s-12 column-width-m-5">
              <a href="https://www.zwemwater.nl/" target="_blank" rel="noopener noreferrer"><img src="/images/zwemwater.nl.png" alt="screenshot van zwemwater.nl" /></a>
              <p style={{ textAlign: "right", fontStyle: "italic" }}><small>zwemwater.nl</small></p>
            </div>
          </div>
        </Section>

        <Section id="blauwalg" bg="dark">
          <div className="columns">
            <div className="column column-width-s-12 column-width-m-10">
              <h2>Blauwalg</h2>
              <p>Blauwalg is voor mensen en zoogdieren een bijzonder giftige bacterie. Blauwalg groeit goed in warm, stilstaand water, dat veel voedingsstoffen bevat. Met name in perioden van langdurige droogte ontstaat gemakkelijk blauwalg. Door de temperatuur en doorstroming van water te meten is redelijk te voorspellen waar blauwalg ontstaat. In de app Zwemwater en op <a href="https://www.zwemwater.nl/" target="_blank" rel="noopener noreferrer">zwemwater.nl</a> kun je voor officiële zwemplekken vinden of er blauwalg is gemeten.</p>
              <h3>Hoe ziet blauwalg eruit?</h3>
              <p>Bij een voldoende hoge concentratie zie je blauwalg met het blote oog als groene vlokken of vlokjes in het water. De blauwe kleurstof waar de naam van komt, zie je pas als er drijflagen ontstaan. Het ontstaan van drijflagen is zeer afhankelijk van temperatuur en wind, en is daardoor slecht te voorspellen. Een drijflaag ziet er soms uit als groene verfstof aan het wateroppervlak en kan voor stankoverlast zorgen.</p>
            </div>
          </div>
        </Section>

        <Section bg="light">
          <div className="columns">
            <div className="column column-width-s-12 column-width-m-10">
              <h2>E. coli en Intestinale enterococcen</h2>
              <p>E. coli (Escherichia coli) en Intestinale enterococcen zijn de bacteriën die bij officiële zwemlocaties worden gemonitord. Ze zijn als gidssoorten aangewezen in de Europese zwemwaterrichtlijn. Ze geven een indicatie of er bronnen van poepbacterien aanwezig zijn. Als dat zo is, zijn er ook andere ziektekiemen in het water Deze metingen op zwemlocaties vind je terug in de app Zwemwater en op <a href="https://www.zwemwater.nl/" target="_blank" rel="noopener noreferrer">zwemwater.nl</a>.</p>
            </div>
          </div>
        </Section>

        <Section bg="dark">
          <div className="columns">
            <div className="column column-width-s-12 column-width-m-10">
              <h2>Temperatuur: oververhitting en onderkoeling</h2>
              <p>In water koelt het menselijk lichaam heel snel af. Zelfs bij warm weer is het daarom van belang op te passen voor onderkoeling. Zo kan plotseling in heel koud water terechtkomen leiden tot hyperventilatie, hartstilstand en verdrinking.</p>
              <p>Water dat kouder is dan 20 graden voelt aan als fris. Bij temperaturen onder de 20 graden wordt het dragen van een wetsuit aangeraden, onder de 16 graden is een wetsuit dragen vaak verplicht bij evenementen/wedstrijden. Wanneer de watertemperatuur 13 graden of minder is, wordt zwemmen afgeraden, zelfs met wetsuit. Open water wedstrijden worden in dat geval afgelast.</p>
              <p>Wanneer de watertemperatuur 22 graden of hoger is, kan het dragen van een wetsuit tot oververhitting leiden. De lichaamswarmte kan dan onvoldoende weg.</p>
            </div>
          </div>
        </Section>
      </Page>
    )
  }
}

export default Zwemwaterkwaliteit