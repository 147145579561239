import React from 'react'

import Page from '../template/Page'
import Header from '../template/Header'

import Uitspoeling from './parts/Uitspoeling'
import FactorenVerontreiniging from './parts/FactorenVerontreiniging'


class WaterInAmsterdam extends React.Component {
  render() {
    return (
      <Page>
        <Header>
          <h1>Waterverontreiniging</h1>
        </Header>

        <FactorenVerontreiniging />
        <Uitspoeling />
      </Page>
    )
  }
}

export default WaterInAmsterdam