const compress = (predicate, treshold = 0) => {
  return function(data) {

    const result = []
    const length = data.length
    if (!length) return []

    let target = []
    let timestamp = data[0].timestamp

    for (let index = 0; index < length; ++index) {
      const value = data[index]
      if (predicate(value)) {
        target.push(value)
      } else {
        if (target.length && (timestamp - target[0].timestamp) >= treshold) {
          result.push(target)
        }
        target = []
      }
      timestamp = value.timestamp
    }

    if (target.length && (timestamp - target[0].timestamp) >= treshold) {
      result.push(target)
    }
    return result
  }
}

// TEST COMPRESS
// const compressOddPer3 = compress(item => item.value % 2 !== 0, 3)
// const mockA = [1,1,1,1,2,2,2,1,1,2,2,1,1,1].map((value, time) => {
//   return { value, time }
// })
// console.log(mockA)
// console.log(compressOddPer3(mockA))


export default compress