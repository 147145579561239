import React from 'react'

class Rioolsystemen extends React.Component {

  componentDidMount(){
    window.objectFitPolyfill()
  }

  componentDidUpdate(){
    window.objectFitPolyfill()
  }

  render() {
    //style={{ backgroundImage: 'url("/images/overstorten_bg.jpg")'}}
    return (
      <section id="rioolsystemen" className="full-imagery">

        <img src="/images/rioolsystemen-light.png" alt="" data-object-fit="cover" />

        <div className="text-panel right">
          <h2>Rioolnetwerk Amsterdam</h2>
          <p>Amsterdam heeft een uitgebreid rioolnetwerk. Op de kaart is zichtbaar dat het gemengde stelsel (in geel) zich voornamelijk in het centrum bevindt. In de wijken buiten het centrum is een gescheiden rioolnetwerk aangelegd. Tijdens een overstortsituatie kan men er vanuit gaan dat er tijdelijk veel schadelijke bacteriële verontreiniging in het watersysteem zit. Met een gunstige stroming en onder invloed van UV-licht is dit echter binnen drie dagen uit het systeem. Ook op de kaart te vinden zijn de zuiveringen bij de haven en die bij Amstelveen. Deze RWZI’s kunnen beschouwd worden als een soort overstorten. Bij zware regenval komen hier grote hoeveelheden bacteriën in het water.</p>
          <h4>Legenda</h4>
          <img src="/images/rioolsystemen-legenda.svg" alt="" style={{ width: "200px", marginTop: "0.75rem" }} />
        </div>

      </section>
    )
  }
}

export default Rioolsystemen
