
export const setLocation = location => ({
  type: 'SET_LOCATION',
  location
})

export const setChartSettings = chartSettings => ({
  type: 'SET_CHART_SETTINGS',
  chartSettings
})

export const setData = data => ({
  type: 'SET_DATA',
  data
})

export const setDomainSettings = domainSettings => ({
  type: 'SET_DOMAIN_SETTINGS',
  domainSettings
})

export const setBrushSettings = brushSettings => ({
  type: 'SET_BRUSH_SETTINGS',
  brushSettings
})

export const setActiveChart = activeChart => ({
  type: 'SET_ACTIVE_CHART',
  activeChart
})

export const setInfoBox = infoBox => ({
  type: 'SET_INFO_BOX',
  infoBox
})

export const setLatest = latest => ({
  type: 'SET_LATEST',
  latest
})